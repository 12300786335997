import React from "react";

import { Maybe, SanityImageWithMeta, SanityVsSpse } from "@graphql-types";
import { Container } from "@util/standard";
import styled from "styled-components";
import BlocksContent from "@components/shared/sub/blockContent";
import { MAX_WIDTH_1440 } from "@util/constants";
import Image from "@components/shared/sub/image";

interface Props {
  data?: Maybe<SanityVsSpse>[];
}

const ImageWrapper = styled.div`
  height: 250px;
  width: 250px;
  margin: 20px auto;
`;

const SPSE = (props: Props) => {
  const { data } = props;

  return (
    <Container
      maxWidth={`${MAX_WIDTH_1440}px`}
      width="80%"
      padding="0px 10% 40px 10%"
      margin="auto"
      mobileWidth="95%"
      border
      mobilePadding="0px 25px 40px 25px"
    >
      {data &&
        data.map((feature, index) => {
          return (
            <Container key={feature?._key ?? ""}>
              <Container
                display="flex"
                flexDirection="row"
                gridGap="95px"
                margin="40px auto"
                padding="40px 0px 0px 0px"
                isMobileColumn
                mobileGridGap="0px"
                key={feature?._key ?? ""}
              >
                <Container
                  display="flex"
                  alignItems="center"
                  mobileMargin="0px auto 50px auto"
                >
                  <ImageWrapper>
                    <Image
                      data={feature?.image as Maybe<SanityImageWithMeta>}
                    />
                  </ImageWrapper>
                </Container>
                <Container mobileTextAlign="center">
                  <BlocksContent
                    blocks={feature?.cta?.firstColumn?._rawColumnContent}
                  />
                </Container>
              </Container>
              {index + 1 !== data.length && <hr />}
            </Container>
          );
        })}
    </Container>
  );
};

export default SPSE;
