import React from "react";

import { Container, MenuButtonGatsbyLink } from "@util/standard";

import { GridWrapperContainer, ImageWrapper } from "./hero.styles";

import Image from "@components/shared/sub/image";
import {
  Maybe,
  SanityBlogs,
  SanityCaseStudies,
  SanityImageWithMeta,
} from "@graphql-types";
import { MAX_WIDTH } from "@util/constants";
import styled from "styled-components";

interface FeaturedBlogPropsBase {
  isCaseStudy?: boolean;
  height?: string;
  mobileHeight?: string;
  featuredBlogPost?: Maybe<SanityBlogs> | Maybe<SanityCaseStudies>;
}

interface FeaturedPropsA extends FeaturedBlogPropsBase {
  isCaseStudy?: false;
  featuredBlogPost?: Maybe<SanityBlogs>;
}

interface FeaturedBlogPropsB extends FeaturedBlogPropsBase {
  isCaseStudy: true;
  featuredBlogPost?: Maybe<SanityCaseStudies>;
}

type FeaturedBlogProps = FeaturedPropsA | FeaturedBlogPropsB;

export const StyledImage = styled(Image)`
  width: 100% !important;
  height: 100% !important;
`;

const StyledSubHeading = styled.p`
  color: #6d7278;
  font-family: "Header bold";
`;

function FeaturedBlog(props: FeaturedBlogProps) {
  const { featuredBlogPost, isCaseStudy } = props;

  const isExternalCaseStudy = isCaseStudy && featuredBlogPost?.isExternal;

  if (featuredBlogPost === null) return null;

  return (
    <GridWrapperContainer
      gridTemplateColumns="50% 50%"
      margin="20px auto 50px auto"
      tabletMargin="0px auto 0px auto"
      tabletWidth="75%"
      maxWidth={MAX_WIDTH}
      featuredBlog
      featuredHero
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        margin="40px 50px"
        mobileMargin="20px 20px"
        height={props.height}
        mobileHeight={props.mobileHeight}
      >
        {isCaseStudy ? (
          <>
            <StyledSubHeading>CASE STUDY</StyledSubHeading>
            <div
              style={{
                width: "100px",
                height: "100px",
                margin: "0px 0px 20px 0px",
              }}
            >
              {featuredBlogPost &&
                (featuredBlogPost as SanityCaseStudies).featuredLogo && (
                  <Image
                    data={
                      (featuredBlogPost as SanityCaseStudies)
                        .featuredLogo as Maybe<SanityImageWithMeta>
                    }
                    width="100%"
                    objectFit="contain"
                  />
                )}
            </div>
            <h3>{featuredBlogPost?.title}</h3>
          </>
        ) : (
          <>
            {featuredBlogPost &&
            (featuredBlogPost as SanityBlogs).blogType === "pressRelease" ? (
              <StyledSubHeading>PRESS RELEASE</StyledSubHeading>
            ) : (
              <StyledSubHeading>BLOG</StyledSubHeading>
            )}
            <p>{featuredBlogPost?.publishdate}</p>
            <h3>{featuredBlogPost?.title}</h3>
            <p>{featuredBlogPost?.authorName}</p>
          </>
        )}

        <p>{featuredBlogPost?.excerpt}</p>

        {isCaseStudy ? (
          <MenuButtonGatsbyLink
            color="secondary"
            href={
              !isExternalCaseStudy
                ? `/case-study/${featuredBlogPost?.slug?.current}`
                : `${featuredBlogPost?.externalUrl}`
            }
            target={isExternalCaseStudy ? "_blank" : ""}
            width="fit-content"
            padding="10px 35px 10px 35px"
            margin="0px"
          >
            Read More
          </MenuButtonGatsbyLink>
        ) : featuredBlogPost?.slug &&
          (featuredBlogPost as SanityBlogs)?.sanityInternal ? (
          <MenuButtonGatsbyLink
            color="secondary"
            href={
              (featuredBlogPost as SanityBlogs)?.blogType === "pressRelease"
                ? `/press-news/${featuredBlogPost?.slug?.current}`
                : `/blog/${featuredBlogPost?.slug?.current}`
            }
            width="fit-content"
            padding="10px 35px 10px 35px"
            margin="0px"
          >
            Read More
          </MenuButtonGatsbyLink>
        ) : (featuredBlogPost as SanityBlogs).link ? (
          <MenuButtonGatsbyLink
            color="secondary"
            href={(featuredBlogPost as SanityBlogs).link?.url ?? ""}
            width="fit-content"
            padding="10px 35px 10px 35px"
            margin="0px"
          >
            {(featuredBlogPost as SanityBlogs).link?.linktext}
          </MenuButtonGatsbyLink>
        ) : null}
      </Container>
      {featuredBlogPost?.featureImage && (
        <ImageWrapper
          noDownloadLinks={true}
          featureBlogPost
          fullHeight
          noMaxDimensions
          noBottomMargin
        >
          <StyledImage
            data={featuredBlogPost?.featureImage}
            objectFit="cover"
          />
        </ImageWrapper>
      )}
    </GridWrapperContainer>
  );
}

export default FeaturedBlog;
